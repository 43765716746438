import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { H2, P } from 'stile-shared/src/components/2020/common/Primitives';
import { Center } from 'stile-shared/src/components/2020/layout/Center';
import { CONTACT } from 'stile-shared/src/constants';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function ConciergePage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/concierge/cover_concierge.png"
            alt="A teacher sits at their desk preparing a lesson plan"
          />
        }
      />

      <TextBlock>
        <P>
          We’re experts in helping schools and districts integrate Stile into their curriculum with
          knowledge that only local teachers have.
        </P>
        <P>
          Whether you’re rebuilding your science curriculum, creating a custom pacing calendar, or
          localizing our lessons, our teachers are here to help!
        </P>
      </TextBlock>

      <Center
        intrinsic
        gutters={theme.space.gutters}
        style={{ marginBottom: `calc(${theme.space.section} * -.5)` }}
      >
        <H2 size="xxl">Stile Concierge services</H2>
      </Center>

      <TeaserBlock
        contentMax="450px"
        asideMax="450px"
        contentMin="450px"
        headingLevel="h3"
        title="Pacing calendar support"
        text="We’ll work with you to create a detailed custom pacing calendar that best incorporates Stile lessons to enhance student learning."
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/concierge/concierge_us_planner.png"
            alt="An illustration of a calendar with a day circled in bright green, and surrounded by stationery and a coffee"
          />
        }
      />

      <TeaserBlock
        contentMax="450px"
        asideMax="450px"
        contentMin="450px"
        headingLevel="h3"
        title="Custom reporting"
        text="We’ll create custom reports that gives further insights into how your school or district is using Stile to enhance teaching and learning."
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/concierge/concierge_us_reports.png"
            alt="An illustration of a school building with various analytics symbols behind it"
          />
        }
      />

      <TeaserBlock
        contentMax="450px"
        asideMax="450px"
        contentMin="450px"
        headingLevel="h3"
        title="Customization support"
        text="While Stile’s intuitive editing tools make it easy to edit lessons, we’re here to help adapt our lessons to local contexts and phenomena."
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/concierge/concierge_us_localization.png"
            alt="An illustration of a Stile lesson, with a newspaper behind it and a map marker. There is a pen 'editing' the lesson"
          />
        }
      />

      <TextBlock textAlign="center">
        <P>Sound too good to be true? We get that a lot!</P>
        <P>
          For more information, get in touch with us at{' '}
          <Link url={CONTACT.email.concierge.url}>{CONTACT.email.concierge.text}</Link>
        </P>
      </TextBlock>

      <NextPageBlock path="/what-is-stile/technology-for-teaching/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default ConciergePage;
